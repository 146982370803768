import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import * as styles from "../css/single-blog.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/SEO";


const Photos = ({ data }) => {
    const { name , description , images} = data.photo.edges[0].node;
    let mainImage = getImage(images[0])
   
   return(
       <Layout>
         <Seo title={name} description={"some really awesome pictures"}/>
           <section className={styles.blog}>
                <h1 className={styles.center}>
                    {name}
                </h1>
                <div className={styles.center}>
                <GatsbyImage image = {mainImage}/>
                <h4>{description}</h4>
                <AniLink fade to="/photos" className="btn-primary">
                    all photos
                </AniLink>
                </div>
           </section>
       </Layout>
   )
}
// so that is what i have to do , 
export const query = graphql`
query getPhoto($slug: String!){
    photo: allContentfulPhotos(filter: {slug: {eq: $slug}}) {
      edges {
        node {
          name
          description
          images {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
export default Photos

